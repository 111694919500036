// keep only first (old) type of document
export const oldVersionKeywords = [
  'Versicherungsschein',
].map((item) =>
  item.toLowerCase().normalize('NFD')
);

// keep only last types docuemnts
export const latestVersionKeywords = [
  'Vertragsinformationen',
  'Änderungs-Nachtrag',
  'Mahnung',
  // 'Abbuchung',
  'Rücktritt',
  'Kündigung',
  'Kündigungsbestätigung',
  'Vertragsbeendigung',
  'Beitragsanpassung',
  'Beitragsrechnung',
].map((item) => item.toLowerCase().normalize('NFD'));
